<template>
  <div class="page-container">
    <el-form>
      <el-form-item label="姓名:">
        {{ userInfo.username }}
      </el-form-item>
      <el-form-item label="用户名:">
        {{ userInfo.account }}
      </el-form-item>
      <el-form-item label="邮箱:" id="email">
        {{ userInfoNew.email }}
      </el-form-item>
      <el-form-item label="所属学院:">
        {{ userInfo.schoolname }} {{ userInfo.departmentname }}
      </el-form-item>
    </el-form>
    <div style="margin-top: 10px">
      <el-button type="primary" size="small" @click="clickChangePasswordBtn">修改密码</el-button>
      <el-button type="default" size="small" @click="clickChangeEmailBtn">修改邮箱</el-button>
    </div>
    <!--弹出框-修改密码-->
    <el-dialog
        title="修改密码"
        :visible.sync="changePassword.dialog"
        width="500px"
        center
        :close-on-click-modal="false"
        v-el-drag-dialog>
      <div class="dialog-container">
        <el-form  label-width="150px" ref="changePasswordForm" :model="changePassword" :rules="changePassword.rule">
          <el-form-item prop="password" label="请输入原密码：">
            <el-input placeholder="请输入" v-model="changePassword.password" type="password" onKeypress="javascript:if(event.keyCode == 32 || event.keyCode == 43)event.returnValue = false;"></el-input>
          </el-form-item>
          <el-form-item prop="password1" label="请输入新密码：">
            <el-input placeholder="请输入" maxlength="12" v-model="changePassword.password1" onKeypress="javascript:if(event.keyCode == 32 || event.keyCode == 43)event.returnValue = false;"
                      type="password"></el-input>
          </el-form-item>
          <el-form-item prop="password2" label="请再次输入新密码：">
            <el-input placeholder="请输入" maxlength="12" v-model="changePassword.password2" onKeypress="javascript:if(event.keyCode == 32 || event.keyCode == 43)event.returnValue = false;"
                      type="password"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button type="default" @click="changePassword.dialog=false">取 消</el-button>
        <el-button type="primary" @click="clickChangePasswordSureBtn">确 认</el-button>
      </div>
    </el-dialog>
    <!--弹出框-修改邮箱-->
    <el-dialog
        title="修改邮箱"
        :visible.sync="changeEmail.dialog"
        width="600px"
        center
        :close-on-click-modal="false"
        v-el-drag-dialog>
      <div class="dialog-container">
        <el-form label-width="150px" ref="changeEmailForm" :model="changeEmail" :rules="changeEmail.rule">
          <el-form-item prop="email" label="请输入新邮箱：">
            <el-input placeholder="请输入" v-model="changeEmail.email"></el-input>
          </el-form-item>
          <el-form-item prop="code" label="请输入验证码：">
            <div class="flex flex-start">
              <el-input placeholder="请输入" maxlength="6" v-model="changeEmail.code"
              ></el-input>
              <el-button style="margin-left: 10px" :disabled="changeEmail.send" type="text" size="small"
                         @click="clickSendEmailCodeBtn">发送验证码至邮箱
              </el-button>
            </div>
            <div>
              <el-button style="margin-left: 10px" type="text" size="small" @click="clickGoEmailIndexBtn">前往邮箱查看
              </el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button type="default" @click="changeEmail.dialog=false">取 消</el-button>
        <el-button type="primary" @click="clickChangeEmailSureBtn">确 认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {msg_err, msg_input, msg_success} from "@/utils/ele_component";
import {mapState} from "vuex";
import elDragDialog from "@/directive/el-drag-dialog";
import {regCheck} from "@/utils/common";
import {LoginModel} from "@/model/exp/LoginModel";

export default {
  name: "userInfo",
  directives: {
    elDragDialog
  },
  computed: {
    ...mapState({
      userInfo: state => state.user
    })
  },
  data() {
    // 密码输入检测
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6 || value.length > 12) {
        callback(new Error("请输入6-12位密码"))
      }
      callback()
    }
    // 密码输入检测
    const validatePassword2 = (rule, value, callback) => {
      if (value.length < 6 || value.length > 12) {
        callback(new Error("请再次输入新密码"))
      }
      if (this.changePassword.password1) {
        if (this.changePassword.password1 !== value) {
          callback(new Error("新密码不一致"))
        }
      }
      callback()
    }
    // 邮箱输入检测
    const validateEmail = (rule, value, callback) => {
      if (!regCheck("email", value)) {
        callback(new Error("请输入正确的邮箱地址"))
      }
      callback()
    }
    // 邮箱验证码检测
    const validateCode = (rule, value, callback) => {
      let reg = /^[0-9]+$/
      if(!reg.test(value)){
        callback(new Error('请输入验证码'))
      }
      if (value.length !== 6 || isNaN(Math.ceil(value))) {
        callback(new Error("请输入6位数字验证码"))
      }
      callback()
    }
    return {
      userInfoNew: {
        email: ""
      },
      changePassword: {
        dialog: false,
        password: "",
        password1: "",
        password2: "",
        rule: {
          password: [{required: true, trigger: 'blur', message: '请输入密码'}],
          password1: [{required: true, trigger: 'blur', validator: validatePassword}],
          password2: [{required: true, trigger: 'blur', validator: validatePassword2}],
        }
      },
      changeEmail: {
        dialog: false,
        email: "",
        code: "",
        send: false,
        rule: {
          code: [{required: true, trigger: 'blur', validator: validateCode}],
          email: [{required: true, trigger: 'blur', validator: validateEmail}]
        }
      }
    }
  },
  mounted() {
    this.userInfoNew = this.userInfo;
  },
  methods: {
    // 点击修改密码按钮
    async clickChangePasswordBtn() {
      this.changePassword.dialog = true
      this.changePassword.password = ""
      this.changePassword.password1 = ""
      this.changePassword.password2 = ""
      setTimeout(() => {
        this.$refs.changePasswordForm.clearValidate();
      }, 300)
    },
    // 点击修改密码弹窗-确认按钮
    async clickChangePasswordSureBtn() {
      this.$refs.changePasswordForm.validate(async valid => {
        if (valid) {
          await LoginModel.editUserInfo({
            oldpassword: this.changePassword.password,
            newpassword: this.changePassword.password1
          })
          msg_success("修改密码成功")
          this.changePassword.dialog = false
        }
      });
    },
    // 点击修改邮箱按钮
    async clickChangeEmailBtn() {
      this.changeEmail.dialog = true
      this.changeEmail.email = ""
      this.changeEmail.code = ""
      this.changeEmail.send = false;
      setTimeout(() => {
        this.$refs.changeEmailForm.clearValidate();
      }, 300)
    },
    // 点击发送邮箱验证码按钮
    async clickSendEmailCodeBtn() {
      let email = this.changeEmail.email;
      if (!email) {
        msg_err("请先输入您的邮箱地址")
        return
      }
      if(!regCheck("email", email)){
        msg_err("邮箱格式错误")
        return;
      }
      await LoginModel.sendBindEmail(this.changeEmail.email)
      this.changeEmail.send = true;
      msg_success("发送验证码成功,请前往邮箱查看")
    },
    // 点击前往邮箱查看按钮
    clickGoEmailIndexBtn() {
      let email = this.changeEmail.email;
      if (!email) {
        msg_err("请先输入您的邮箱地址")
        return
      }
      // 获取邮箱类型
      let company = (email.split("@")[1]).split(".")[0]
      let companyCom = email.split("@")[1]
      msg_success("请允许网页弹窗打开您的邮箱登录页!")
      window.open("http://mail." + companyCom)
      // let companyIndex = {
      //   "qq": "http://mail.qq.com",
      //   "sina": "http://mail.sina.com",
      //   "126": "http://mail.126.com",
      //   "163": "http://mail.163.com",
      // }
      // if (companyIndex[company]) {
      //   msg_success("请允许网页弹窗打开您的邮箱登录页!")
      //   window.open(companyIndex[company])
      // } else {
      //   msg_err("未匹配您的邮箱登录页，请您主动前往登录!")
      // }
    },
    // 点击修改邮箱弹窗-确认按钮
    async clickChangeEmailSureBtn() {
      this.$refs.changeEmailForm.validate(async valid => {
        if (valid) {
          await LoginModel.editUserInfo({
            email: this.changeEmail.email,
            code: this.changeEmail.code+"",
          })
          msg_success("修改邮箱成功")
          this.changeEmail.dialog = false
          this.userInfoNew.email = this.changeEmail.email
        }
      });
    }
  }
}
</script>

<style scoped lang="less">

</style>